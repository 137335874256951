import * as React from 'react'
import loadable from '@loadable/component'

const FieldSet = loadable(() =>
    import('/src/components/form-components/FieldSet')
)
const FieldSpacer = loadable(() =>
    import('/src/components/form-components/FieldSpacer')
)
const TextField = loadable(() =>
    import('/src/components/form-components/TextField')
)

const StepContactDetails = ({ isSubmitting, isSubmitted }) => {
    return (
        <FieldSet>
            <FieldSpacer grow={'stretch'}>
                <TextField
                    label={'Email Address'}
                    name={'email'}
                    type={'email'}
                    placeholder={'e.g. name@email.com'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                    
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <TextField
                    label={'Contact Number'}
                    name={'phone'}
                    pattern={'number'}
                    placeholder={'e.g. 07700900796'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                    required
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <TextField
                    label={'Postcode'}
                    name={'postcode'}
                    placeholder={'e.g. ZE2 7UL'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                    required
                />
            </FieldSpacer>
        </FieldSet>
    )
}

export default StepContactDetails
